.scrollable-box {
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .scrollable-box::-webkit-scrollbar {
    display: none;
  }
  
  .inner-box {
    display: flex;
    flex-wrap: nowrap;
  }
  
  .inner-box > div {
    width: 100px;
    height: 50px;
    margin: 10px;
    border: 1px solid #ddd;
    padding: 10px;
  }
  .inner-box .box-button {
    width: 150px;
    
    
    background-color: #0093E9;
  background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
  
    border: none;
    font-size: 20px;
    color: white;
    padding: 1px 25px;
    font-weight: 500;
    border-radius: 3px;
    margin: 3px 3px 3px 0px;
  }
  .inner-box .box-button-1{
    width: 150px;
    
    
    background: #6D6027; 
  background: -webkit-linear-gradient(to right, #D3CBB8, #6D6027);
  background: linear-gradient(to right, #D3CBB8, #6D6027); 
    border: none;
    font-size: 20px;
    color: white;
    padding: 1px 25px;
    margin: 1px 4px;
    font-weight: 500;
    border-radius: 3px;
    margin: 3px;
  }
  .inner-box .box-button-2{
    width: 150px;
    
    
    background: #667db6; 
  background: -webkit-linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6);
  background: linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6); 
  
    border: none;
    font-size: 20px;
    color: white;
    padding: 1px 25px;
    margin: 1px 4px;
    font-weight: 500;
    border-radius: 3px;
    margin: 3px;
  }
  .inner-box .box-button-3{
    width: 150px;
    
    
    background: #22c1c3; 
  background: -webkit-linear-gradient(to right, #fdbb2d, #22c1c3);
  background: linear-gradient(to right, #fdbb2d, #22c1c3); 
  
    border: none;
    font-size: 20px;
    color: white;
    padding: 1px 25px;
    margin: 1px 4px;
    font-weight: 500;
    border-radius: 3px;
    margin: 3px;
  }
  .inner-box .box-button-4{
    width: 150px;
    
   
    background: #ff9966; 
  background: -webkit-linear-gradient(to right, #ff5e62, #ff9966);
  background: linear-gradient(to right, #ff5e62, #ff9966); 
  
    border: none;
    font-size: 20px;
    color: white;
    padding: 1px 14px;
    margin: 1px 4px;
    font-weight: 500;
    border-radius: 3px;
    margin: 3px;
  }
  .inner-box .box-button-5{
    width: 150px;
    
    
    background: #0cebeb; 
  background: -webkit-linear-gradient(to right, #29ffc6, #20e3b2, #0cebeb);
  background: linear-gradient(to right, #29ffc6, #20e3b2, #0cebeb); 
  
    border: none;
    font-size: 20px;
    color: white;
    padding: 1px 25px;
    margin: 1px 4px;
    font-weight: 500;
    border-radius: 3px;
    margin: 3px;
  }
  .inner-box .box-button-6{
    width: 150px;
    
    
    background: #642B73; 
  background: -webkit-linear-gradient(to right, #C6426E, #642B73);
  background: linear-gradient(to right, #C6426E, #642B73); 
  
    border: none;
    font-size: 20px;
    color: white;
    padding: 1px 25px;
    margin: 1px 4px;
    font-weight: 500;
    border-radius: 3px;
    margin: 3px;
  }
  .inner-box .box-button-7{
    width: 150px;
    
    
    background: #283c86; 
  background: -webkit-linear-gradient(to right, #45a247, #283c86);
  background: linear-gradient(to right, #45a247, #283c86); 
  
    border: none;
    font-size: 20px;
    color: white;
    padding: 1px 18px;
    margin: 1px 4px;
    font-weight: 500;
    border-radius: 3px;
    margin: 3px;
  }
  