.market-match{
    margin-top: 10px;
  }
  .my-market h4{
    width: 100%;
    border: 2px solid gray;
    border-radius: 10px;
    background-color: white;
    padding: 5px;
    box-shadow: 1px 1px 4px orange;
    font-size: 30px;
  }
  svg.ball {
    width: 35px;
    font-size: 23px;
  }
  
  span.mrkt-match{
    font-weight: 100;
  }
  
  button.v\/s {
    width: 60%;
    padding: 3px 0;
    text-decoration: none;
    font-size: 14px;
    color: black;
    background-color: white;
    border-radius: 5px;
    border: none;
    margin: 10px 0;
  }
  .my-market p{
    margin: 15px 0px;
  }
  