.account-statment {
    padding: 9px;
  }
  .account-back {
    border-radius: 10px;
    background-color: #0d2137;
    padding: 5px;
    box-shadow: 1px 1px 4px gray;
    color: white;
    font-size: 12px;
    margin: 1% 1% 2% 1%;
  }
  .all-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin: 1% 0%;
  }
  button.account-statement-button {
    background-color: #1f272b;
    color: #dd7f2a;
  }
  button.account-statement-button:focus {
    background-color: #dd7f2a;
    color: white;
    border: none;
  }
  .all-buttons button{
    padding: 5px;
  }
  .form input {
    font-size: 11px;
  }
  .dropdown.self-dropdown {
    width: 98%;
    margin-left: 1%;
  }
  input.d-and-t {
    width: 48%;
    background-color: white;
  }
  .date-time-1 {
    width: 45%;
    margin: 0 10px;
  }
  .date-time {
    display: flex;
    margin: 0 1%;
    justify-content: space-between;
  }
  .new-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  input.btn-submit {
    width: 90px;
  }
  a.table-match {
    text-decoration: none;
  }
  .table-container {
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .scrollable-table {
    border-collapse: collapse;
    width: 100%;
    background-color: #f2f2f2;
  }
  
  .scrollable-table th, .scrollable-table td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
    font-weight: 400;
    font-size: 10px;
  }
  
  .scrollable-table th {
    background-color: #9e6600;
    font-weight: 100;
    color: white;
  }
  input.btn-submit {
    background-color: #1b262b;
    color: white;
    width: 48%;
    margin: 10px;
  }
  .scrollable-table td.credit{
    color: rgb(23, 127, 23);
  }
  .scrollable-table td.debit{
    color: rgb(232, 34, 34);
  }
  li.back-statement {
    padding: 4px;
    margin-left: 100px;
  }
  
  
  select.select-dropdown{
    width: 100%;
    border: none;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: white;
  }
  
  input.search{
    width: 100%;
    border: none;
    padding: 5px;
    border-radius: 5px;
    margin-left: 0;
  }
  .all-buttons input:focus{
    outline: none;
  }
  .select-dropdown option{
    width: 50%;
  }
  table.pl{
    margin-top: 10px;
    background-color: #f2f2f2;
    width: 100%;
    max-width: 98%;
    margin: 1%;
  }
  table.pl tr td {
    border: 1px solid gray;
    padding: 10px;
    font-size: 12px;
    font-weight: 100;
  }
  
  button.filter{
    border: 2px solid orange;
    width: 47%;
    padding: 5px;
    font-size: 18px;
    margin-left: 8px;
    background-color: orange;
    color: white;
    border-radius: 5px;
    box-shadow: 2px 3px 4px #1b262b;
  }
  button.clear{
    border: 2px solid #1b262b;
    width: 47%;
    padding: 5px;
    font-size: 18px;
    margin-left: 5px;
    background-color: #1b262b;
    color: orange;
    border-radius: 5px;
    box-shadow: 2px 3px 4px orange;
  }
  
  ul.button-next {
    display: flex;
    justify-content: end;
    margin-top: 15px;
    margin-bottom: 10px !important;
  }
  .button-next button {
    padding: 5px 10px;
    margin: 0px 1px;
  }
  
  .options li {
    margin: 0px 5px;
    font-weight: 100;
  }
  
  ul.options.option-listing {
    width: 100%;
    max-width: 80%;
    justify-content: center;
    margin: 3% 0% 1% 10%;
  }
  .option-listing li select{
    border: 1px solid gray;
  }
  ul.options.option-listing li select:focus {
    border: none;
    outline: none;
  }
  button.table-match{
    border: none;
    color: rgb(24, 79, 207);
  }