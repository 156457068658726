.success-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .success-content {
    background: white;
    padding: 5px;
    border-radius: 8px;
    text-align: center;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  
  .close-button {
    margin-top: 10px;
    padding: 5px 20px;
    border: none;
    background-color: red;
    color: white;
    border-radius: 5px;
    box-shadow: 1px 1px 4px gray;
  }
  
  .success-sign {
    width: 80%;
    height: 100%;
    transform: translate3d(0px, 0px, 0px);
    content-visibility: visible;
    padding: 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
  }
  
  .message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  
  p.var{
    margin-top: -30px;
  }
  