.change-password-container input:focus{
    outline: none;
  }
  .change-password-container input{
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    padding: 5px; 
  }
  .change-password-button{
    width: 100%;
    padding: 10px;
    border: 2px solid lightgray;
    border-radius: 5px;
    background-color: rgb(21, 28, 32);
    color: white;
    box-shadow: 1px 1px 5px white;
    font-size: 12px;
  }

  .hidden{
    display: none;
  }
  