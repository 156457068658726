.navbar-account.hidden {
    display: none;
  }
  
  .vertical-navbar {
    width: 100%;
    top: 0x;
    left: 0;
    background-color: #fff;
    display: flex;
  }
  
  .vertical-navbar ul {
    list-style-type: none;
    padding: 0;
    width: 100%;
    margin: 0;
  }
  
  .vertical-navbar ul li button.ac-button {
    display: block;
    padding: 15px 20px;
    text-decoration: none;
    font-size: 18px;
    width: 100%;
    color: black;
    font-weight: 400;
    text-align: start;
  }
  
  .vertical-navbar ul li button.ac-button:hover {
    background-color: #575757;
  }
  
  
  
  .navbar-account {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(25, 30, 35);
    padding: 15px 5px;
  }
  
  
  
  
  .nav-links {
    list-style: none;
    display: flex;
  }
  
  .nav-links li {
    margin: 0 15px;
  }
  
  .h5,
  h5 {
    font-size: 1.25rem;
    font-weight: 300;
  }
  
  
  .main-content {
    padding: 20px;
    text-align: center;
  }
  
  
  .navbar-small {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(255 255 255);
    padding: 1px 20px;
    height: 90px;
  }
  
  button.btn.btn-danger.account {
    margin-top: 40px;
    margin-left: 20%;
    padding: 10px;
    width: 60%;
    font-size: 20px;
  }
  
  button.btn.btn-dark.bet {
    width: 40%;
    display: flex;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 1.2), 0 6px 20px 0 rgba(0, 0, 0, -0.81);
  }
  
  button.btn.btn-dark.replay {
    margin-left: 100px;
  }
  
  span.demo {
    font-size: 20px;
  }
  
  .offcanvas-body {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0px 5px;
  }
  
  .navbar-account.hidden {
    display: none;
  }
  
  .offcanvas.offcanvas-end.show {
    width: 70%;
    z-index: 1050;
  }
  
  .button-83 {
    appearance: button;
    background: rgb(33, 173, 98);
    background: linear-gradient(40deg, rgba(33, 173, 98, 1) 29%, rgba(28, 250, 10, 1) 80%);
    border: 0 solid #e5e7eb;
    border-radius: .5rem;
    box-sizing: border-box;
    color: white;
    column-gap: 1rem;
    cursor: pointer;
    display: flex;
    font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 100%;
    font-weight: 700;
    line-height: 24px;
    margin-right: 10px;
    outline: 2px solid transparent;
    padding: 14px 12px;
    text-align: center;
    text-transform: none;
    transition: all .1s cubic-bezier(.4, 0, .2, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    box-shadow: -6px 8px 10px rgba(81, 41, 10, 0.1), 0px 2px 2px rgba(81, 41, 10, 0.2);
  }
  
  .withdraw {
    appearance: button;
    background: rgb(173, 33, 33) !important;
    background: linear-gradient(40deg, rgba(173, 33, 33, 1) 29%, rgba(255, 0, 0, 1) 73%) !important;
    border: 0 solid #e5e7eb;
    border-radius: .5rem;
    box-sizing: border-box;
    color: white;
    column-gap: 1rem;
    cursor: pointer;
    display: flex;
    font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 100%;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    outline: 2px solid transparent;
    padding: 14px 10px;
    text-align: center;
    text-transform: none;
    transition: all .1s cubic-bezier(.4, 0, .2, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    box-shadow: -6px 8px 10px rgba(81, 41, 10, 0.1), 0px 2px 2px rgba(81, 41, 10, 0.2);
  }
  
  .button-83:active {
    background-color: #f3f4f6;
    box-shadow: -1px 2px 5px rgba(81, 41, 10, 0.15), 0px 1px 1px rgba(81, 41, 10, 0.15);
    transform: translateY(0.125rem);
  }
  
  .button-83:focus {
    box-shadow: rgba(72, 35, 7, .46) 0 0 0 4px, -6px 8px 10px rgba(81, 41, 10, 0.1), 0px 2px 2px rgba(81, 41, 10, 0.2);
  }
  
  svg.icon-button {
    font-size: 18px;
    color: rgb(57, 57, 57);
  }
  
  button.ac-button {
    background: #ffffff;
    margin: 10px 0;
    border-radius: 10px;
    box-shadow: 0px 1px 0px gray;
    border: none;
  }
  
  @media (min-width: 320px) {
    .navbar-account {
      padding: 10px 0px;
      width: 100%;
    }
  
    button.btn.btn-dark.bet {
      padding: 4px 5px;
      margin-left: 4px;
    }
  
    button.btn.btn-dark.replay {
      margin-left: 40px;
      height: 38px;
      padding: 4px 4px;
    }
  
    button.btn.btn-dark.setting {
      margin-left: 0px;
      padding: 4px 4px;
      margin-right: 8px;
    }
  
    .navbar-small {
      padding: 0;
    }
  
    .button-83 {
      width: 48%;
      font-weight: 500;
      font-size: 0.813rem;
      padding: 10px 10px;
    }
  
    .withdraw {
      width: 48%;
      font-weight: 500;
      font-size: 0.688rem;
      padding: 10px 10px;
    }
  
    .vertical-navbar ul li button.ac-button {
      padding: 8px 10px;
      font-size: 1rem;
      font-weight: 400;
    }
  
    .vertical-navbar ul li button.ac-button:focus {
      background-color: white;
    }
  }
  
  @media (min-width: 375px) and (max-width: 424px) {
    button.btn.btn-dark.replay {
      margin-left: 85px;
    }
  
    .button-83 {
      width: 48%;
      font-weight: 500;
      font-size: 1.063rem;
      padding: 10px 10px;
    }
  
    .withdraw {
      width: 48%;
      font-weight: 500;
      font-size: 0.875rem;
      padding: 10px 10px;
    }
  }
  
  @media (min-width: 424px) {
    button.btn.btn-dark.replay {
      margin-left: 120px;
    }
  
    .button-83 {
      width: 48%;
      font-weight: 400;
      font-size: 1.25rem;
      padding: 10px 10px;
    }
  
    .withdraw {
      width: 48%;
      font-weight: 400;
      font-size: 1.063rem;
      padding: 10px 10px;
    }
  }
  
  