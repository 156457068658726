.login-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background:#0000007d;
    animation: fadeIn 0.5s ease;
  }
  
  .login-popup-content {
    background-color: #1b1e20;
    padding: 0px 20px;
    border-radius: 10px;
    width: 400px;
    box-shadow: 0 5px 15px rgb(73 72 72);
    position: relative;
    animation: slideIn 0.3s ease;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    color: #ffffff;
  }
  
  form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .submit-btn {
    width: 100%;
    padding: 4px;
    background-color: hsl(345deg 100% 47%);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 3px 8px gray;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-50px);
    }
    to {
      transform: translateY(0);
    }
  }
  
  .form-logo {
    width: 200px;
    /* margin: 50px 0px 50px 50px; */
  }
  h3 {
    text-align: center;
    color: white;
    margin-bottom: 25px;
  }
  .input-label-above{
    color: #ffffff;
    font-size: 15px;
  }
  .password-label{
    color: #ffffff;
    font-size: 15px;
  }
  
  .username {
    border: 1px solid;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
  }
  
  .icon {
    display: flex;
    margin-right: 8px;
    cursor: pointer;
    padding: 10px;
    border-radius: 6px;
    border-left: none;
  }
  
  .input-label {
    padding: 10px;
    border-radius: 6px;
    border-left: none;
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    width: 100%;
    z-index: 10;
  }
  
  .dropdown-menu div {
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdown-menu div:hover {
    background-color: #f0f0f0;
  }
  input.login-input{
    background: none;
    border: none;
  }
  input.login-input:focus{
    outline: none;
    border: none;
  }
  input.password-input:focus{
    outline: none;
    border: none;
  }
  .dropdown-menu.show {
    display: block;
    width: 10%;
    margin-left: 200px;
  }
  
  .password {
    border: 1px solid;
    border-radius: 10px;
    background-color: rgb(228, 224, 224);
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 25px;
  }
  .pushable {
    background: hsl(340deg 100% 32%);
    border-radius: 12px;
    border: none;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
  }
  .pushable-login {
    background: hsl(340deg 100% 32%);
    border-radius: 12px;
    border: none;
    width: 100px;
    padding: 0;
    margin: 2px 0px 0px 4px;
    cursor: pointer;
    outline-offset: 4px;
  }
  .front {
    display: block;
    padding: 4px 42px;
    border-radius: 12px;
    font-size: 1.25rem;
    /* background: hsl(345deg 100% 47%); */
    color: white;
    /* transform: translateY(-6px); */
  }
  .front-login {
    display: block;
    padding: 10px 4px;
    width: 100px;
    border-radius: 12px;
    font-size: 20px;
    background: hsl(345deg 100% 47%);
    color: white;
    /* transform: translateY(-6px); */
  }
  
  .pushable:active .front {
    transform: translateY(-2px);
  }
  .pushable-login:active .front-login {
    transform: translateY(-2px);
  }
  p.error {
    text-align: center;
    color:hsl(345deg 100% 47%);
    font-size: 20px;
    margin: 4px 0px;
  }
  span.other-login {
    text-align: center;
    width: 100%;
    display: block;
    color: white;
    margin: 10px 0;
    text-shadow: 1px 0px 5px hsl(0deg 0% 74.96%);
  }
  span.other-button {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }
  button.google {
    width: 45%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  button.whatsapp {
    width: 45%;
    padding: 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
  }
  img.google-image {
    height: 30px;
    margin: 0 7px;
  }
  img.whatsapp-image {
    height: 35px;
    margin: 0 6px;
  }
  span.new-account {
    width: 100%;
    display: block;
    color: white;
    text-align: center;
    margin: 10px 0;
  }
  .password-input {
    width: 80%;
    padding: 8px;
    border: none;
    border-radius: 10px;
  }
  
  .password-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .signup-password{
    display: flex;
  }
  
  