@media(min-width:768px) and (max-width:2000px){
    .page-wrapper.chiller-theme.toggled {
        padding-right: 5px;
        width: 25%;
        position: absolute;
    }
    .new-menu-bar {
        background: black;
        /* height: 100vh; */
        width: 70%;
        margin-left: 225px;
        margin-top: 18px;
    }
}
@media(min-width:1050px) and (max-width:1200px){
    .new-menu-bar {
    background: black;
    /* height: 100vh; */
    width: 80%;
    margin-left: 240px;
    margin-top: 18px;
}
}
@media(min-width:1201px) and (max-width:1300px){
    .new-menu-bar {
    background: black;
    /* height: 100vh; */
    width: 74%;
    margin-left: 277px;
    margin-top: 18px;
}
}
@media(min-width:1301px) and (max-width:2000px){
    .new-menu-bar {
    background: black;
    /* height: 100vh; */
    width: 74%;
    margin-left: 300px;
    margin-top: 18px;
}
}
@media(min-width:900px) and (max-width:1050px){
    li.casino-links img {
        width: 180px !important;
        height: 115px !important;
        /* margin: 4px 13px; */
        object-fit: fill;
        border: 1px solid  #8000FF;
        border-radius: 7px;
    }
}
@media(min-width:1051px) and (max-width:2000px){
    li.casino-links img {
    width: 245px !important;
    height: 150px !important;
    /* margin: 4px 13px; */
    object-fit: fill;
    border: 1px solid #8000FF;
    border-radius: 7px;
}
}
/* ************************************************************* */
.loader {
    position: absolute;
    top: 40%;
    right: 0%;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
    background: rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    padding: 34px 100px 34px 115px;
}
.loader {
    width: fit-content;
    font-size: 40px;
    font-family: system-ui, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    color: #0000;
    -webkit-text-stroke: 1px #ffffff;
    background: conic-gradient(#ffffff 0 0) 0 / 0% 100% no-repeat text;
    animation: l1 1s linear infinite;
    display: none; /* Initially hidden */
}
.loader:before {
    content: "Loading";
}
@keyframes l1 {
    to {
        background-size: 120% 100%;
    }
}
body{
    background:black;
}
.wrapper {
display: block;
width: 100%;
}

.new-menu-bar {
/* Add any styles as needed */
}

#slot-nav {
display: flex;
width: 100%;
overflow: auto;
}

li span {
width: max-content;
display: block;
}

.image-section > div {
display: none; /* Hide all images by default */
}

.image-section > div.active {
display: block; /* Show only the active image */
}
ul.casinos {
display: grid;
grid-template-columns: 1fr 1fr 1fr;
grid-gap: 0px;
margin: 16px 8px;
}
li.casino-links img {
    width: 108px;
    height: 80px;
    /* margin: 4px 13px; */
    object-fit: fill;
    border: 1px solid  #8000FF;
    border-radius: 7px;
}
button.active-button {
    background:linear-gradient(270deg, #b2102f, #ff1744);;
    color: white;
    border: none;
    border-radius: 2px;
    padding: 4px 20px;
    font-size: 18px;
}
.slot-games-li{
    /* background-color: #f36c21; */
    color: black;
    border: none;
    border-radius: 2px;
    font-size: 18px;
    border-left: 1px solid white;
    margin: 1px;
}
ul.sub-nav-ul{
    background-color: black;
}
.li-button {
    background: none;
    border: none;
    color: white;
    padding: 4px 20px;
    font-size: 18px;
}
.new-menu-bar {
    background: black;
    /* height: 100vh; */
}
ul{
    margin-bottom: 0px !important;
}
li.casino-links {
    display: flex;
    justify-content: center;
    align-items: center;
}
.active-tab {
background-color: #ffcc00 !important; /* Yellow color */
color: black !important;
font-weight: bold;
border-radius: 5px;
}