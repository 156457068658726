.profile-container {
  /* margin-top: 10%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 5px;
  border-radius: 5px;
  background-color: white;
  flex-direction: column;
  box-shadow: 1px 1px 5px gray;
  margin-top: 4%;
}

.card-container h1 {
  text-shadow: 1px 2px 4px gray;
}

.main-card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 10px;
}

.small-card {
  flex: 0 0 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 20px;
}

.withdraw-button {
  margin-top: 4%;
}

.withdraw-button button {
  border: none;
  padding: 4px 10px;
  border-radius: 5px;
  background-color: orange;
  color: white;
}

.account-info {
  margin-top: 10px;
  text-align: center;
}

.bank-image {
  width: 30%;
  display: flex;
  position: relative;
  justify-content: center;
  margin-left: -10%;
  background: white;
  box-shadow: 2px 2px 5px gray;
  border-radius: 5px;
}

.bank-image img {
  width: 80%;
  padding: 10px;
}


.account-info span {
  font-size: 1.4rem;
  font-weight: 700;
}

.profile-details {
  flex: 1;
  margin-left: 10px;
  font-size: 12px;
}

.profile-details table {
  width: 100%;
  border-collapse: collapse;
}

.profile-details td {
  padding: 2%;
  border-bottom: 1px solid #ddd;
}

.action-buttons {
  display: flex;
  /* margin: 5px 4px; */
  justify-content: flex-end;
  gap: 5px;
}

.edit-button {
  margin: 0 10px;
  padding: 2px 10px;
  border: none;
  border-radius: 5px;
  font-size: 10px;
}

@media (max-width: 768px) {
  .main-card {
    align-items: center;
  }

  .profile-details {
    margin-left: 7%;
  }
}

@media (min-width : 768px) {
  .bank-image {
    margin-left: -6%;
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.save-button,
.cancel-button {
  margin-right: 10px;
}

.save-button {
  background-color: green;
  color: white;
  border: none;
  padding: 4px 10px;
}

.cancel-button {
  background-color: red;
  color: white;
  border: none;
  padding: 4px 10px;
}

.empty {
  height: 60px;
}