body {
  position: sticky;
  background-color: #e4e5e9;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  scrollbar-width: none;
}

:root {
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.in-play-effect {
  animation: effect 1s infinite;
  color: #508d0e;
  font-weight: 700;
  transition: 0.4s ease-in;
}

@keyframes effect {
  0% {
    color: #508d0e;
    transform: scale(1);
  }
  50% {
    color: #508d0e;
    transform: scale(1);
  }
  100% {
    color: red;
    transform: scale(1);
  }
}

.event-box .matches .a {
  color: black;
}
.matches {
  text-decoration: none;
}
.activated {
  border: 2px solid orangered;
}
