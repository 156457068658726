.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    color: white;
    margin: 15px 0;
  }
  
  .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #ffffff;
  }
  
  .pushable-signup {
  background: hsl(42, 100%, 32%);
  border-radius: 12px;
  border: none;
  width: 100px;
  padding: 0;
  margin: 17px 13px 14px 19px;
  cursor: pointer;
  outline-offset: 4px;
  }
  .front-signup {
    display: block;
    padding: 0px 0px;
    border-radius: 12px;
    font-size: 15px;
    background: hsl(49, 100%, 47%);
    color: white;
    /* transform: translateY(-6px); */
  }
  .pushable-signup:active .front-signup {
    transform: translateY(-2px);
  }
  
  button.pushable-signup.submit-btn.login-trigger {
    /* margin-left: 40px; */
  }
  .phnumber-signup {
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background-color: #ffffff;
    position: relative;
    padding: 5px 0;
}
  span.country-code {
  text-align: center;
  font-size: 15px;
  /* padding: 11px 0 0 10px; */
  border: none;
  margin-left: 2px;
  }
  input.phnumber {
  border: none;
  font-size: 15px;
  background: none;
  }
  input.phnumber:focus {
  border: none;
  outline: none;
  }
  .phnumber-signup div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  }
  button.getotp {
  width: 40%;
  background: #e9b02e;
  border: none;
  margin: 4px;
  border-radius: 5px;
  }
  input.otp-input {
  margin-bottom: 10px;
  }
  .signup-password{
  display: flex;
  margin-bottom: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  align-items: center;
  }
  .password-toggle-signup {
  /* position: absolute;
  right: 30px;
  top: 43%;
  transform: translateY(-50%); */
  cursor: pointer;
  }
  .confirm-password-toggle-signup {
  /* position: absolute;
  right: 30px;
  top: 51%;
  transform: translateY(-50%); */
  cursor: pointer;
  }
  .signup-otp {
    margin: 10px 0;
}
.signup-otp input{
  border-radius: 10px;
}

.login-popup-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}