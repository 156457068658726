.account-statement{
    padding: 9px;
  }
  .account-back {
    display: flex;
    padding: 0px 25px;
  }
  .all-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  
  button.account-statement-button {
    background-color: #1f272b;
    color: #dd7f2a;
  }
  button.account-statement-button:focus {
    background-color: #dd7f2a;
    color: white;
    border: none;
  }
  .all-buttons button{
    padding: 5px;
  }
  
  input.btn-submit {
    width: 90px;
  }
  a.table-match {
    text-decoration: none;
  }
  .table-container {
    overflow-x: auto;
    white-space: nowrap;
  }

  /* Table styling */
.scrollable-table {
    border-collapse: collapse;
    width: 100%;
    background-color: #f2f2f2;
  }
  
  .scrollable-table th,
  .scrollable-table td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
    font-weight: bold;
  }
  
  .scrollable-table th {
    background-color: #f2f2f2;
    font-weight: 500;
  }
  input.btn-submit {
    background-color: #1b262b;
    color: white;
    width: 45%;
    margin: 10px;
  }
  .scrollable-table td.credit{
    color: rgb(23, 127, 23);
  }
  .scrollable-table td.debit{
    color: rgb(232, 34, 34);
  }
  li.back-statement {
    padding: 4px;
    margin-left: 100px;
  }
  li.ac-statement {
    padding: 6px;
    color: white;
    margin-left: 15px;
  }
  select.select-dropdown{
    width: 100%;
    border: 2px solid;
    padding: 5px;
    border-radius: 5px;
  }
  
  .all-buttons input:focus{
    outline: none;
  }
  option.select-option{
    background-color: aqua;
  }
  .account-statement-button{
    width: 20px !important;
  }
  select.select-dropdown.for-ac {
    width: 48%;
  }