.main-header {
    background-color: #0d2137;
    text-align: center;
    padding: 0.25rem 0; 
    margin: 3.25rem 0 0.1875rem 0; 
    height: 1.875rem; 
    display: flex;
    font-size: 0.9375rem; 
    color: white;
    margin-top: 0.125rem;
  }
  
  .main-header span.main-balance {
    display: flex;
  }
  
  .main-header span.exposure-balance {
    display: flex;
    margin-left: 0.9375rem; 
  }
  
  .main-header span p {
    color: white;
    margin-left: 0.3125rem; 
  }
  
  .coin{
    color: rgb(255, 215, 0);
    margin-left: 5.375rem;
    font-size: 0.625rem;
  }
  
  @media screen  and (max-width: 320px) {
    .coin{
        margin-left: 10%;
    }
  }
  
  @media (max-width: 374px) and ( min-width: 320px) {
  .coin {
    color: rgb(255, 215, 0);
    margin-left: 2.375rem;
    font-size: 0.625rem;
  }
  }
  @media (max-width: 424px) and ( min-width: 375px) {
  .coin {
    color: rgb(255, 215, 0);
    margin-left: 4.375rem;
    font-size: 0.625rem;
  }
  }
  