body {
  background-color: #eef0f3 !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.match-update-header {
  display: flex;
  background-color: rgb(255, 119, 0);
  padding-bottom: 8px;
  justify-content: space-between;
}

.match-update-header span {
  color: white;
  font-size: 15px;
  vertical-align: middle !important;
}

.match-update-header p {
  line-height: 1.2;
  color: white;
  font-size: 10px;
  margin: 1px 0px 0px 25px;
}

.header-part-2 {
  display: flex;
  background-color: #262a2e;
  padding-bottom: 8px;
  justify-content: space-around;
}

.header-part-2 a {
  text-decoration: none;
  margin: 0 13px;
  color: white;
  font-size: 12px;
  padding-top: 5px;
  position: relative;
  display: flex;
  transition: color 0.3s ease;
  align-items: center;
}

.header-part-2 a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: #ffffff;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.header-part-2 a:active::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.scoring-area img {
  width: 100%;
  height: 150px;
}

.match-status {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 10px 3px;
}

.match-status span {
  display: flex;
  align-content: center;
  justify-items: start;
  align-items: center;
  font-weight: 600;
}

span.black {
  margin-left: 220px;
}

span.lay {
  margin-left: 30px;
}

span.black1 {
  margin-left: 150px;
}

.team-name-box {
  display: flex;
  border: 1px solid lightgray;
  justify-content: space-between;
}

.team-name {
  width: 50%;
}

.team-number {
  display: flex;
}

.team-number {
  width: 93px;
  display: flex;
  margin: 0px 0px 0px 60px;
  font-size: 13px;
  justify-content: flex-end;

  align-items: center;
}

.match-odds-heading,
.bookmaker-heading,
.match-status-heading-toss,
.fancy-heading {
  display: flex;
  /* width: 100%; */
  /* margin-left: 3px; */
  font-size: 14px;
  /* background: rgb(255, 119, 0); */
  height: 30px;
  line-height: 30px;
  color: #ffffff;
  float: left;
  font-weight: bold;
  padding-right: 30px;
  padding-left: 10px;
  position: relative;
  background: linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%);
  border-top-right-radius: 15px;
  font-size: 0.75rem;
  width: auto;
  align-items: center;


}

.back-lay {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 10px;
}

span.team-name {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 700;
  text-wrap: wrap;
}

span.team-name-match {
  margin-left: 10px;
  font-size: 13px;
  font-weight: normal;
  text-wrap: wrap;
  font-weight: 700;
}

.team-back-number {
  min-width: 80%;
  flex-shrink: 0;
  background-color: rgb(114, 187, 239);
  /* border: 1px solid #ffffff; */
  padding: 5px;
  text-align: center;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  display: flex;
  text-wrap: balance;
  height: 40px;
  flex-direction: column;
  line-height: 1;
}

.team-lay-number {
  background-color: rgb(250, 169, 186);
  min-width: 80%;
  flex-shrink: 0;
  /* border: 1px solid #ffffff; */
  padding: 5px;
  text-align: center;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 40px;
  flex-direction: column;
  line-height: 1;
}

/* .match-status-heading-toss {
  margin-top: 15px;
  font-size: 14px;
  margin-left: 10px;
} */

.team-toss-box {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}

.team-toss.A {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 100;
}

.team-toss.B {
  font-size: 14px;
  white-space: nowrap;
  font-weight: 100;
}

.toss-box {
  border-radius: 2px;
  width: 125px;
  text-align: center;
  /* padding: 6px 0px; */
  display: grid;
  margin: 0px 0px 6px 0px;
  /* background-color: #84bdee; */
  --tw-bg-opacity: 1;
  background-color: rgb(114 227 160/var(--tw-bg-opacity));
  height: 34px;
  line-height: 1;
  border: 1px solid white;
  justify-content: center;
}

.toss-box-number {
  display: flex;
  justify-content: space-around;
}

.match-status-heading {
  margin-top: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
  color: #000000;
  border: none;
  padding: 0;
  font-size: 13px;
  border-bottom: 1px solid #7e97a7;
  word-wrap: break-word;
  position: relative;
}

.premium-cricket {
  border-bottom: 2px solid lightgray;
}

.premium-cricket span {
  font-size: 22px;
  padding: 10px;
}

.cricket-premium {
  border-bottom: 3px solid #0f0f1e;
  display: inline-block;
  padding: 14px;
  border-radius: -4px;
}

.match-updates-container {
  position: relative;
}

.dropdown-container {
  margin-top: 20px;
}

.place-bet {
  background-color: white;
  border: 1px solid lightgray;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  width: 200px;
  margin-top: 20px;
}

@media (min-width: 320px) {
  span.black {
    margin-left: 110px;
  }
}

@media (min-width: 375px) and (max-width: 424px) {
  span.black {
    margin-left: 165px;
  }
}

@media (min-width: 425px) {
  span.black {
    margin-left: 215px;
  }
}

hr {
  margin: 0rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  padding: 10px;
  border-radius: 10px;
  /* width: 400px; */
  position: relative;
}

.blue-bg {
  background-color: rgb(179, 209, 235);
  color: white;
}

.pink-bg {
  background-color: #ecb7de;
  color: black;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 25px;
  cursor: pointer;
  color: white;
}

.match-details-toss {
  display: flex;
  justify-content: space-around;
  width: 100%;
  background-image: linear-gradient(90deg, #82dda6cc, #82ddb059 49%, #82dda6cc);
}

/* websocket wrok related css start */

.suspend_bm {
  display: none;
  height: 46px;
  background-color: #243a4866;
  position: absolute;
  width: 40%;
  left: 59%;
  z-index: 5;
  text-align: center;
}

.suspend_bm_check {
  line-height: 46px;
  font-size: 12px;
  font-weight: normal;
  opacity: 0.8;
  color: #ffffff;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
  text-transform: none;
  padding: .5rem;
  background-color: transparent;
  box-shadow: inset 0 0 0 9999px transparent;
}

/* 
.bookmaker_back_1_css{
  padding: 0;
  vertical-align: middle;
  text-align: center;
  font-size: .75rem;
  font-weight: bold;
  border-top: none;
  background-color: transparent;
  border-bottom-width: 1px;
  border-bottom-color: #7e97a7;
  border-bottom-style: solid;
  box-shadow: inset 0 0 0 9999px transparent;
} */

.runner_css {
  /* width: 65%; */
  text-align: left;
  /* padding-left: 6px; */
  /* vertical-align: middle; */
  font-size: .75rem;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1.5px;
  /* padding-bottom: 1.5px;
    font-weight: bold;
    border-top: none;
    background-color: transparent;
    border-bottom-width: 1px;
    border-bottom-color: #7e97a7;
    border-bottom-style: solid;
    box-shadow: inset 0 0 0 9999px transparent; */
}

/* 
.suspended-container {
  display: none; 
  height: 46px;
  background-color: #243a4866;
  position: absolute;
  width: 40%;
  left: 59%;
  z-index: 5;
  text-align: center;
}

.suspended-text {
  line-height: 46px;
  font-size: 12px;
  font-weight: normal;
  opacity: 0.8;
  color: #ffffff;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
  text-transform: none; 
  padding: 0.5rem;
  background-color: transparent;
  box-shadow: inset 0 0 0 9999px transparent;
} */

.bookmaker-back-1 {
  padding: 0;
  /* Equivalent to p-0 */
  text-align: center;
  vertical-align: middle;
  /* Equivalent to align-middle */
  font-size: 0.75rem;
  /* Equivalent to text-[.75rem] */
  font-weight: bold;
  /* Equivalent to font-bold */
  background-color: transparent;
  /* bg-transparent */
  border-top: none;
  /* border-t-[none] */
  border-bottom: 1px solid #7e97a7;
  /* border-b-[1px] border-b-[#7e97a7] */
  box-shadow: inset 0 0 0 9999px transparent;
}

.table-row {
  /* Dynamic class, handled in React, for rows */
  /* position: relative; remove css */
  /* Custom styling if needed */
}

.bg-highlight {
  background-color: #1a8ee1 !important;
  color: #fff;
  /* Equivalent to text-[#fff] */
  box-shadow: inset 0 1px 3px #00000080;
  /* Equivalent to [box-shadow:inset_0_1px_3px_#00000080] */
}

.bg-danger {
  background-color: #f4496d;
  /* Equivalent to bg-[#f4496d] */
  color: #fff !important;
  /* Equivalent to !text-[#fff] */
  box-shadow: inset 0 1px 3px #00000080;
  /* Equivalent to [box-shadow:inset_0_1px_3px_#00000080] */
}

.suspended {
  opacity: 1.5;
  pointer-events: none;
  position: relative;
  /* Ensure positioning for pseudo-element */
}

.suspended::after {
  content: "Suspended";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ff0000;
  font-size: 20px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 6px 0px;
  border-radius: 4px;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fancy_game_status {
  opacity: 1.6;
  pointer-events: none;
  position: absolute;
  right: 8%;
  transform: translate(18%, 0%);
  color: #ef0808;
  font-size: 12px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px 8px;
  z-index: 10;
  height: 40px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fancy_game_status_update {
  font-weight: bold !important;
  width: 80px;
}



.back-1.spark,
.back-gradient .spark,
.lay-gradient .spark,
.lottery-ball.spark {
  animation: .6s sparkBack
}

@keyframes sparkBack {

  0%,
  100% {
    background-color: #72bbef;
    color: #1e1e1e
  }

  50% {
    background-color: #f8e71c;
    color: #fff
  }
}

.back-2.spark {
  animation: .6s sparkBack_2
}

@keyframes sparkBack_2 {

  0%,
  100% {
    background-color: #b7d5eb;
    color: #1e1e1e
  }

  50% {
    background-color: #f8e71c;
    color: #fff
  }
}

.back-3.spark {
  animation: .6s sparkBack_3
}

@keyframes sparkBack_3 {

  0%,
  100% {
    background-color: #d7e8f4;
    color: #1e1e1e
  }

  50% {
    background-color: #f8e71c;
    color: #fff
  }
}

.lay-1.spark,
.lay-gradient .spark {
  animation: .6s sparkLay
}

@keyframes sparkLay {

  0%,
  100% {
    background-color: #faa9ba;
    color: #1e1e1e
  }

  50% {
    background-color: #26f1f8;
    color: #fff
  }
}

.lay-2.spark {
  animation: .6s sparkLay_2
}

@keyframes sparkLay_2 {

  0%,
  100% {
    background-color: #efd3d9;
    color: #1e1e1e
  }

  50% {
    background-color: #26f1f8;
    color: #fff
  }
}

.lay-3.spark {
  animation: .6s sparkLay_3
}

@keyframes sparkLay_3 {

  0%,
  100% {
    background-color: #f6e6ea;
    color: #1e1e1e
  }

  50% {
    background-color: #26f1f8;
    color: #fff
  }
}

@keyframes effect {
  0% {
    transform: scale(1);
    color: #508d0e;
  }

  50% {
    transform: scale(1);
    color: #508d0e;
  }

  100% {
    transform: scale(1);
    color: red;
  }
}


/* websocket wrok related css end */


/* start add new css after feedback */

.market-exposure[data-value],
.win[data-value] {
  color: green !important;
  fill: green;
}

.market-exposure[data-value^="-"],
.win[data-value^="-"] {
  color: red !important;
  fill: red;
}



/* end add new css after feedback */

.matched-amount {
  padding: 5px 0;
  float: right;
  font-size: 0.788rem;
  text-align: end;
  background: transparent;
  width: 155px;
  position: absolute;
  right: 0;
  padding-right: 10px;
  color: #000000;
}

.matched-amount span {
  font-weight: bold;
  font-size: 0.788rem;
  color: #000000;
}
/* 
thead {
  vertical-align: bottom; remove css
  font-size: 0.75rem;
} */
/* 
th {
  border-bottom: 0px; remove css
  padding: 5px;
  text-align: center;
  display: block;
} */

.min-max {
  padding: 0;
  margin: 0;
  height: 20px;
  display: flex;
  font-size: 0.625rem;
  line-height: 7px;
  border-bottom: 1px solid;
  align-items: center;
  justify-content: center;
  background-color: #bed5d8;
  border-radius: 3px;
}

.min-max dt {
  display: inline-block;
  margin-right: 8px;
  color: #535353;
  font-weight: bold;
  font-size: 0.625rem;
  text-align: center;
}

.min-max dd {
  display: inline-block;
  margin: 0;
  color: #000000;
  font-size: 0.625rem;
  text-align: center;
}

.back {
  border-bottom: 1px solid #7e97a7;
  padding: 5px;
  text-align: center;
  min-width: 75px;
  background-color: #72bbef;
  border-left: 1px solid #fff;
}

.lay {
  border-bottom: 1px solid #7e97a7;
  padding: 5px;
  text-align: center;
  min-width: 75px;
  background-color: #faa9ba;
  /* border-left: 1px solid #fff; */
}

 /* extra add css */
 
.book-model {
  position: relative;
  top: 4px;
}

@media (min-width: 1024px) {
  .book-model {
      top: 28px;
      width: 352px !important;
  }
}