body{
    color: black !important;
    padding-bottom: 30px;
  }
  .navbar-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #0d2137;
      z-index: 1000; 
      display: flex;
      justify-content: center;
      padding: 8px 0;
      height: 50px;
      margin-top: 1%;
    }
    
    .icons {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }
  
    .offcanvas-fullscreen {
      width: 100vw; 
      height: 100vh; 
      z-index: 1050; 
    }
    button.footer-button {
      background: none;
      padding: 0px 0px;
      border: none;
  }
    button.footer-button p {
      color: white;
      line-height: 15px;
      font-size: 10px;
  }
    
  
  @media (max-width: 370px) {
    .icons {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin: 0 0px;
    }
  }
  