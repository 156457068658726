.scrolling-text {
    background-color: rgb(234, 255, 0);
    font-size: 0.75rem;
    height: 1.25rem;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 100;
  }
  
  .scrolling-text-content {
    animation: scroll-left linear infinite;
    transform: translateX(100%);
  }
  
  @keyframes scroll-left {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  
  @media (max-width: 375) { 
    .scrolling-text {
      height: 2.5rem;
    }
  }
  