
.deposit {
    font-size: 1.25rem;
    background-color: rgb(255 255 255);
    padding: 5px;
    border-radius: 10px;
  }
  .scroll-container {
  overflow-x: auto;
  scrollbar-width: none;
  white-space: nowrap;
  margin: 0 5px;
  }
  .payments {
    display: flex;
    flex-wrap: nowrap;
    margin: 10px 3px;
  }
  .payments li {
    margin: 5px;
    cursor: pointer;
  }
  .payments img{
    width: 67px;
  }
  .payments-symbol {
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 2px gray;
  background-color: white;
  text-align: center;
  padding: 0px 4px;
  height: 165px;
  position: relative;
  }
  
  .payments-symbol h3{
  margin-bottom: 0;
  }
  
  p.see-more {
  font-size: 14px;
  text-align: center;
  }
  
  .scroll-button {
    border: none;
    background: none;
    color: gray;
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    z-index: 1;
  }
  .scroll-button.left {
    left: 0;
  }
  
  .scroll-button.right {
    right: 0;
  }
  .payments-options{
    color: orange;
    font-weight: 200;
  }
  .deposit-withdraw{
    padding: 10px;
  }
  p {
    margin-top: 0;
    margin-bottom: 0rem !important;
    font-weight: 100;
  }
  .amount-input{
    width: 100%;
    height: 45px;
    padding: 8px;
    font-size: 1.25rem;
    border-radius: 15px;
    border: 1px none;
    box-shadow: 0 0 4px gray;
  
  }
  .amount-button {
  width: 25%;
  margin: 5px 5px;
  padding: 8px;
  border-radius: 10px;
  background: rgb(255,168,0);
  background: linear-gradient(40deg, rgba(255,168,0,1) 29%, rgba(250,210,10,1) 80%);
  color: white;
  font-size: 1rem;
  box-shadow: 0px 2px 2px gray;
  border: none;
  }
  
  .file-upload {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .file-upload-label {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background-color: #f8f9fa;
    cursor: pointer;
    position: relative;
    background: white;
    box-shadow: 0px 1px 2px gray;
  }
  
  .file-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  
  .file-icon {
    margin-right: 10px;
    font-size: 1rem;
    color: gray;
  }
  
  .file-upload-text {
    font-size: 10px;
    color: gray;
  }
  .unique{
    width: 100%;
    height: 45px;
    padding: 8px;
    font-size: 1.25rem;
    border-radius: 15px;
    border: 1px solid;
  }
  .submit{
    width: 100%;
    height: 45px;
    padding: 8px;
    font-size: 1.25rem;
    border-radius: 15px;
    border: 1px solid;
    margin: 10px 0px;
  }
  .input-container {
    margin: 10px 0px;
  }
  .account {
  margin: 8px 0px;
  font-size: 14px;
  font-weight: 400;
  }
  .unique-transaction {
    margin: 15px 0px;
  }
  
  input[type="checkbox"] {
  width: 10px !important;
  height: 10px !important;
  }
  .details {
  margin-top: 16px;
  padding: 8px;
  background-color: white;
  border-radius: 10px;
  }
  
  .details h3 {
  text-align: center;
  color: orange;
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 100;
  }
  
  .click {
  display: grid;
  align-content: center;
  justify-content: end;
  padding: 9px;
  box-shadow: 0px 1px 2px gray;
  border-radius: 10px;
  }
  .input-container h3 {
    text-align: center;
    color: orange;
    font-weight: 100;
    margin-bottom: 10px;
    font-size: 12px;
  }
  .payment-slip h3 {
  text-align: center;
  color: orange;
  margin-bottom: 10px;
  font-size: 10px;
  font-weight: 100;
  }
  
  .details h4 {
    color: orange;
    text-align: center;
    font-size: 1rem;
    font-weight: 100;
  }
  
  p.details {
  font-size: 11px;
  font-weight: 100;
  }
  
  p.details{
  font-size: 10px;
  font-weight: 100;
  }
  
  .payment-details input {
  width: 100%;
  height: 30px;
  font-size: 10px;
  border-radius: 10px;
  border: none;
  box-shadow: 0 1px 2px gray;
  font-weight: 400;
  }
  
  /* Button CSS */
  
  .section-submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  }
  button.payment-submit-button {
  position: relative;
  width: 200px;
  height: 50px;
  border: 0;
  outline: none;
  background: #0d2137;
  color: #fff;
  font-size: 22px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  cursor: pointer;
  }
  .checked {
  width: 50px;
  height: 50px;
  border-radius: 40px;
  box-shadow: 0 0 12px -2px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  right: -40px;
  opacity: 0;
  background: #d90429;
  }
  .checked svg {
  width: 40px;
  margin: 5px 8px;
  }
  .checked path {
  stroke-width: 3;
  stroke: #fff;
  stroke-dasharray: 34;
  stroke-dashoffset: 34;
  stroke-linecap: round;
  }
  .payment-submit-button.active {
  background: #d90429;
  transition: 0.6s;
  }
  .active p {
  transition: 0.6s;
  margin-right: 100px;
  }
  .active .checked {
  opacity: 1;
  transition: 0.6s;
  right: 0;
  }
  .active .checked path {
  transition: 0.6s;
  transition-delay: 0.6s;
  stroke-dashoffset: 0;
  }
  
  p.submit-button-text{
  font-size: 22px;
  }
  
  .terms-conditions{
  font-size: 10px;
  }
  
  .terms-conditions-link{
  font-size: 10px;
  }
  
  @media (min-width: 320px) {
  .amount-button {
    width: 90%;
    padding: 4px;
  }
  }
  
  .button-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  justify-content: start;
  margin-bottom: 15px;
  }
  