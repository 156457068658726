.container {
    padding: 1.5rem;
    max-width: 400px;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    background: rgb(220,164,224);
  background: linear-gradient(93deg, rgb(236, 186, 222, 1) 16%, rgb(179, 209, 235, 1) 81%);
  
  }
  
  .header-refer {
    color: white;
    text-align: center;
  }
  
  .reward-section {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .gift-icon {
    width: 5.5rem;
    margin-bottom: 1rem;
  }
  
  .coin-point {
    display: flex;
    align-items: center;
  }
  
  .reward-section h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: white;
  }
  
  .reward-section  span {
    font-size: 1rem;
    font-weight: normal;
    color: white;
  }
  
  .reward-section p {
    font-size: 1rem;
    line-height: 1.5;
    color: white;
  }
  
  .referral-code {
    margin-bottom: 1.5rem;
  }
  
  .referral-code p {
    font-size: 1rem;
    color: #ffffff;
  }
  
  .code-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding: 0.75rem;
    border-radius: 0.625rem;
    margin: 0.75rem 0;
    color: #6300db;
    font-weight: bold;
    font-size: 1.25rem;
  }
  
  .copy-btn {
    background-color: #6300db;
    color: white;
    border: none;
    border-radius: 0.3rem;
    padding: 0.3rem 0.6rem;
    margin-left: 0.625rem;
    cursor: pointer;
    font-size: 0.875rem;
  }
  
  .share-text {
    margin: 0.75rem 0;
  }
  
  .social-buttons {
    margin-bottom: 1.5rem;
    display: flex;
  }
  
  .social-buttons .btn {
    border: none;
    padding: 0.75rem 1rem;
    border-radius: 50px;
    font-size: 0.875rem;
    margin: 0.5rem;
    cursor: pointer;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  
  .telegram {
    background-color: #00bfff;
    color: white;
  }
  
  .facebook {
    background-color: #3b5998;
    color: white;
  }
  
  .whatsapp {
    background-color: #25d366;
    color: white;
  }
  
  .faq-section {
    background-color: #ffffff;
    color: black;
    padding: 1.5rem;
    border-radius: 1rem;
  }
  
  .faq-section h3 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: #4e00b5;
  }
  
  .faq-item {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    border-bottom: 1px solid #ccc;
    padding: 0.5rem 0;
    cursor: pointer;
  }
  
  .faq-item span {
    color: #4e00b5;
  }
  
  @media (min-width: 768px) {
    .container {
        max-width: 600px;
    }
    .header h1 {
        font-size: 2rem;
    }
    .reward-section h2 {
        font-size: 2.5rem;
    }
    .code-box {
        font-size: 1.5rem;
    }
  }
  
  @media (min-width: 1024px) {
    .container {
        max-width: 800px;
    }
    .header h1 {
        font-size: 2.5rem;
    }
    .reward-section h2 {
        font-size: 3rem;
    }
    .code-box {
        font-size: 1.75rem;
    }
  }
  