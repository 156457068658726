ol, ul {
    padding-left: 0rem !important;
  }
  .payment-details input {
    width: 100%;
    height: 30px;
    font-size: 10px;
    border-radius: 10px;
    text-align: justify;
    border: none;
    box-shadow: 0 0 1px gray;
    font-weight: 100;
    padding-left: 5px;
    margin-bottom: 0;
  }
  input:focus {
    outline: none;
    border: none;
  }
  input.checkbox {
    width: 15px;
    height: 20px;
  }
  .otp input {
    width: 70%;
  }
  button.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    margin-left: 0px;
    height: 30px;
    border-radius: 10px;
    background: rgb(255,168,0);
    background: linear-gradient(40deg, rgba(255,168,0,1) 29%, rgba(250,210,10,1) 80%);
    box-shadow: 1px 2px 2px gray;
    border: none;
    color: white;
    margin-top: 5px;
  }
  .pre-account {
    text-align: center;
  }
  .payments li{
    cursor: pointer;
  }
  button.see-more-btn {
    background: white;
    box-shadow: 1px 1px 5px #bababa;
    padding: 4px;
    margin-left: 10px;
    border: none;
    outline: none;
    color: gray;
  }
  .pre-account img{
    width: 50px;
  }
  .pre-account span{
    font-size: 20px;
    font-weight: bold;
  }
  .withdraw-payments {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0; 
    padding: 0;
    overflow-x: hidden;
    scroll-behavior: smooth;
    width: 100%;
    white-space: nowrap;
  }
  li.click-account {
    background: rgb(25,74,149);
    background: linear-gradient(40deg, rgba(25,74,149,1) 25%, rgba(12,94,136,1) 100%);
    padding: 7px;
    margin: 10px;
    color: white;
    box-shadow: 0px 2px 2px gray;
    width: 43%;
    font-size: 0.688rem;
  }
  .withdraw-payments-symbol{
    margin-top: 10px;
    border: none;
    border-radius: 17px;
    box-shadow: 1px 2px 4px gray;
    background-color: white;
    text-align: center;
    padding: 2px 10px;
    position: relative;
  }
  
  .withdraw-payments-symbol p{
    font-size: 12px;
  }
  .withdraw-payments-symbol span{
    font-size: 12px;
    font-weight: 100;
  }
  .withdraw-payments-symbol h3{
    font-size: 1rem;
  }
  .details h4{
    color: orange;
    text-align: center;
  }
  p.details{ 
    font-size: 10px;
    font-weight: 100;
  }
  .agree {
    margin-bottom: 5px;
  }
  
  .payment-methods{
    margin-top: 15px;
  }
  
  ul.payment-types {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
  li.payment-apps {
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 2px 5px gray;
    display: grid;
    justify-content: space-evenly;
    align-items: center;
    justify-items: center;
    font-size: 0.625rem;
  }
  li.payment-apps img {
    width: 60%;
    aspect-ratio: 3/2;
    object-fit: contain;
  }
  
  
  li.click-account button{
    border: none;
    background: none;
    color: white;
  }
  
  .withdraw-form-input{
    margin: 10px 0;
  }
  .withdraw-form-input p{
    font-size: 12px;
  }
  .payment-btn{
    border: none;
  }
  
  .withdraw-payments-symbol h3{
    margin-bottom: 0;
  }
  
  .methods-heading p {
    text-align: center;
    font-size: 12px;
    font-weight: 100;
    text-shadow: 0px 0px 2px gray;
  }

  .profile-container {
    margin-top: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.custom-button {
  padding: 10px;
  color: #fff;
  height: 30px;
  margin-left: 12px;
  display: flex;
  background-color: #010203;
  border-radius: 6px;
  border: 1px solid #010203;
  float: right;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}