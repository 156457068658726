.sticky-header {
  align-content: space-around;
  align-items: center;
  background-color: #0d2137;
  box-shadow: 0 .125rem .3125rem #0000001a;
  display: block;
  height: 3.125rem;
  justify-content: space-around;
  left: 0;
  padding: 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.header-logo {
  width: 6.25rem;
}

.offcanvas-backdrop {
  z-index: 1000;
}

.navbar-toggler {
  background-color: transparent;
  border: none;
}

@media (max-width: 48rem) {
  .navbar-collapse {
    display: none;
  }
}

button.navbar-toggler.me-2.btn.btn-dark {
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2.875rem;
  height: 1.5rem;
  background-color: white;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  transition: transform 0.3s ease-in-out;
  position: relative;
  border-radius: 0.25rem;
}

.hamburger .bar1,
.hamburger .bar2,
.hamburger .bar3 {
  width: 100%;
  height: 0.1875rem;
  background-color: white;
  /* margin: 2px 0; */
  border-radius: 0.125rem;
  transition: all 0.3s ease-in-out;
}

.hamburger .bar1,
.hamburger .bar2,
.hamburger .bar3 {
  background-color: gray;
  border-radius: .125rem;
  height: .1875rem;
  transition: all .3s ease-in-out;
  width: 100%;
}

.hamburger.open .bar1 {
  transform: rotate(45deg) translate(0.3125rem, 0.3125rem);
}

.hamburger.open .bar2 {
  opacity: 0;
}

.hamburger.open .bar3 {
  transform: rotate(-45deg) translate(0.3125rem, -0.3125rem);
}

.hamburger:focus {
  outline: none;
  border: none;
  background: none;
}

.hamburger:active {
  outline: none;
  border: none;
  background: none;
  border-color: none;
}

.navbar-toggler:focus {
  /* text-decoration: none; */
  outline: 0;
  background-color: white;
  /* box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); */
}

.hamburger:focus-visible {
  outline: none;
  border: none;
  background: none;
  box-shadow: none;
}

.hamburger:target {
  outline: none;
  border: none;
  background: none;
}

button.btn.btn-warning {
  width: 4.375rem;
  padding: 0.1875rem 0.4375rem;
  font-size: 0.875rem;
  margin-left: 6.25rem;
}

button.btn.btn-danger {
  width: 4.375rem;
  padding: 0.1875rem 0.4375rem;
  font-size: 0.875rem;
  margin-left: 0.9375rem;
}

.d-flex.justify-content-between.align-items-center.container-fluid {
  padding: 0 0;
  height: 3.125rem;
  margin: 0 0;
}

.offcanvas.offcanvas-start.show {
  width: 60%;
}

.user-section {
  border-radius: 0.3125rem;
  /* margin-left: 0.625rem; */
  width: 100%;
}

.user-section .user-name {
  color: white;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-30 {
  align-items: center;
  border: none;
  background: rgb(33, 173, 98);
  background: linear-gradient(40deg, rgba(33, 173, 98, 1) 29%, rgba(28, 250, 10, 1) 80%);
  box-shadow: 0px 1px 2px gray;
  border-radius: 0.25rem;
  color: rgb(255, 255, 255);
  height: 1.875rem;
  font-size: 1rem;
  margin-left: 1.5rem;
  padding: 0 0.25rem;
}

.user-button {
  background: rgb(255, 161, 0);
  background: linear-gradient(40deg, rgba(255, 161, 0, 1) 29%, rgba(255, 226, 0, 1) 73%);
  box-shadow: 0px 1px 2px gray;
  margin-left: 0.438rem;
  border: none;
  border-radius: 0.25rem;
  padding: 4px 0.25rem;
  display: flex;
  align-items: center;
}

@media (max-width:318px) {
  .user-section {
    display: flex;
  }

  .button-30 {
    margin-left: 0%;
    width: 100%;
    font-size: 0.688rem;
  }

  .user-button {
    display: flex;
    width: 100%;
    font-size: 0.75rem;
    margin-right: 2%;
    padding: 5px;
  }
}

@media(min-width:318px) {
  .user-section {
    display: flex;
    justify-content: flex-end;
    margin-right: 0.313rem;
  }

  .button-30 {
    margin-left: 13%;
  }
}


li.nav-item {
  border-bottom: 1px solid;
}


.login-btn-header {
  background: none;
  border: none;
}

.login-btn-header span {
  display: block;
  padding: 4px 15px;
  border-radius: 5px;
  font-size: 16px;
  background: hsl(345deg 100% 47%);
  color: white;
}

.signUp-btn-header {
  background: none;
  border: none;
}

.signUp-btn-header span {
  display: block;
  padding: 4px 15px;
  border-radius: 5px;
  font-size: 16px;
  margin-left: 5px;
  background: hsl(49, 100%, 47%);
  color: white;
}


