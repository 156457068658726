.placebet-container {
  display: flex;
  margin-top: 20% !important;
  flex-direction: column;
  background: white;
  padding: 15px;
  border-radius: 10px;
  position: relative;
}

.placebet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.placebet-description {
  margin: 0px 5px 10px 5px;
}



.input-group {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.btn {
  padding: 3px 7px;
  font-size: 17px;
  color: white;
  border: none;
  border-radius: 0px;
  cursor: pointer;
}

.btn:hover {
  background-color: #0056b3;
}

input[type="number"] {
  /* width: 85px; */
  width: 80%;
  padding: 3px 8px;
  margin: 0px 0px;
  /* text-align: center; */
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 0px;
}

.rate-box {
  display: flex;
  justify-content: space-between;
}

.inc-dec-button {
  display: flex;
  height: 30px;
}

.permanent-number {
  padding: 10px 33px;
  font-size: 16px;
}

.price-boxes {
  display: grid;
  margin-top: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5px;
  flex-direction: row;
  justify-items: center;
}

button.price-box-1 {
  border: none;
  text-align: center;
  color: white;
  background-color: #2b3e4e;
  padding: 4px;
  width: 100%;
  margin: 2px;
  border-radius: 5px;
}

.action-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

button.cancel-btn {
  border: none;
  padding: 5px 15px;
  background-color: red;
  color: white;
  margin: 4px 0;
  border-radius: 5px;
}

button.placebet-btn {
  border: none;
  padding: 5px 10px;
  background-color: #1b5e20;
  color: white;
  margin: 4px 0;
  border-radius: 5px;
}


.close-icon {
  cursor: pointer;
  font-size: 24px;
}

button#increase {
  padding: 5px;
  width: 20%;
  border: none;
  background-color: lightslategray;
  display: flex;
  align-items: center;
  justify-content: center;
}

button#decrease {
  padding: 5px 5px 5px 5px;
  width: 20%;
  border: none;
  background-color: lightslategray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.placebet-bg {
  background-color: aqua;
}

.input-number-box {
  margin-left: 5px;
  display: flex;
  height: 30px;
}