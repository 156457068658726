.main {
    margin: 2px 0px;
    overflow-x: hidden; 
    overflow-y: hidden; 
    white-space: nowrap;
    max-width: 100%; 
    position: relative;
  }
  .orange-box {
    display: flex;
    width: 100%;
    background-color: rgb(255, 119, 0);
    padding: 10px;
    margin: 10px;
    box-sizing: border-box;
  }
  .orange-box h1{
    color: #ffff;
  }
  
  .header{
    display: flex;
    background-color: rgb(255, 119, 0);
  }
  .header h1{
    color: white;
    font-size: 23px;
  }
  .cricket {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    width: 100%;
    overflow-x: auto; 
  }
  .header-box {
    display: flex;
    align-items: center;
    width: 55%;
  }
  
  .cricket .header-box span{
    font-size: 18px;
    font-weight: 400;
  }
  
  .bet-numbers {
    display: flex;
    justify-content: space-around;
    width: 145px;
    margin-right: 0px;
  }
  
  .box {
    width: 15%;
    text-align: center;
  }
  .cricket .box span{
    font-size: 13px;
  }
  
  .event-box {
    display: flex;
    width: 100%;
    text-align: center;
    border: 1px solid #d1d1d1;
    background-color: white;
  }
  
  .matches{
    cursor: pointer;
  }
  
  span.date {
    font-size: 10px;
    margin-top: 5px;
  }
  
  .event-box .date-time-box {
    font-size: 10px;
    border-right: 1px solid gray;
    white-space: nowrap;
    height: 40px;
    display: flex;
    width: 150px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .event-box .date-time-box p{
    font-size: 11px;
    color: rgb(255, 119, 0);
  }
  .event-box .matches {
    font-size: 10px;
    padding: 5px 10px;
    white-space: nowrap;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 100%;
  }
  .date-time-box p{
    line-height: 0.2;
    margin-top: 10px;
    margin-bottom: 2px;
  }
  
  .event-box .matches .a{
    width: 120px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden; 
    transition: transform 0.3s; 
    text-wrap: wrap;
    line-height: 1;
    text-align: left;
  }
  
  .event-numbers {
    font-size: 10px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    width: 310px;
    gap: 1px;
    box-sizing: border-box;
    height: 40px;
    align-content: center;
    align-items: center;
  }
  
  .event-numbers::-webkit-scrollbar {
    display: none; 
  }
  
  .event-numbers .box {
    min-width: 33%;
    flex-shrink: 0;
    background-color: rgb(114 187 239);
    border: 1px solid #ffffff;
    padding: 5px;
    text-align: center;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 38px;
  }
  
  .event-numbers .box-pink {
    min-width: 33%;
    flex-shrink: 0;
    background-color: rgb(250 169 186); 
    border: 1px solid #ffffff;
    padding: 5px;
    text-align: center;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 38px; 
  }
  
  .event-numbers .box p{
    color: black;
    line-height: 1;
  }
  .event-numbers .box-pink p{
    color: black;
    line-height: 1;
  }
  
  @media (max-width: 391px) and (min-width: 300px) {
    .event-box .date-time-box {
        font-size: 10px;
        border-right: 1px solid gray;
        white-space: nowrap;
        height: 40px;
    }
    .event-box .matches {
        font-size: 10px;
        padding: 5px 3px;
        white-space: nowrap;
        display: flex;
        height: 40px;
        align-items: center;
    }
    .event-box .matches .a {
        width: 135px;
        overflow: hidden;
        white-space: nowrap;
        text-wrap: wrap;
    }
  }
  @media (max-width: 361px) and (min-width: 300px) {
    .event-box .date-time-box {
        font-size: 10px;
        border-right: 1px solid gray;
        white-space: nowrap;
        height: 40px;
    }
    .event-box .matches {
        font-size: 10px;
        padding: 2px 5px;
        white-space: nowrap;
        height: 40px;
    }
    .event-box .matches .a {
        width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-wrap: wrap;
    }
    .bet-numbers {
        display: flex;
        justify-content: space-between;
        width: 100px;
        margin-right: 15px;
    }
  }
  @media (max-width: 345px) and (min-width: 300px) {
    .event-box .date-time-box {
        font-size: 10px;
        border-right: 1px solid gray;
        white-space: nowrap;
        height: 40px;
    }
    .event-box .matches {
        font-size: 10px;
        padding: 5px 3px;
        white-space: nowrap;
        height: 40px;
    }
    .event-box .matches .a {
        width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-wrap: wrap;
    }
    .bet-numbers {
        display: flex;
        justify-content: space-between;
        width: 100px;
        margin-right: 15px
    }
  }
  .live {
    margin-top: 0;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    background-color: #0d2137;
    color: white;
    font-size: 20px;
    font-weight: 300;
    width: 100% !important;
    border-radius: 3px;
    padding: 6px 21px;
  }
  li{
    list-style: none;
  }
  li.live-li{
    margin-left: 7px;
  }
  