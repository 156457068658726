.small-image-slider{
    border: 2px solid;
    width: 100%;
    height: 50vh;
}
.live-casino-heading p {
    font-size: 13px;
    font-weight: 600;
    /* margin-top: 15px; */
    margin-bottom: 6px !important;
    padding: 3px;
}
.casino-background{
    margin: 4px 0;
    padding: 1px 0;
    /* background-color: white; */
}

.inner-box.scroll-casino {
    width: 500px;
}
button.image-setting {
    border: none;
}
button.image-setting img {
    border: none;
    width: 120px;
    margin: 1px 4px;
    border-radius: 2px;
    box-shadow: 1px 2px 2px #bbbbbb;
}

button.image-setting-casino{
    border: none;
}

button.image-setting-casino img{
    border: none;
    width: 120px;
    margin: 0 4px;
    border-radius: 2px;
    box-shadow: 1px 2px 2px #bbbbbb;
}

button.image-setting-live-casino{
    border: none;
}

button.image-setting-live-casino img{
    border: none;
    width: 110px;
    margin: 0 4px;
    border-radius: 2px;
    box-shadow: 1px 2px 2px #bbbbbb;
}

.live-casino-heading {
    background: #0d2137;
    padding-left: 5px;
    color: white;
}

li.image-setting-live-casino img {
    width: 110px;
    margin: 4px 0;
    box-shadow: 0px 2px 2px #0d2137;
}
.casino-provider-layout {
    display: flex;
    justify-content: space-evenly;
}