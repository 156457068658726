
.table-container {
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .scrollable-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .scrollable-table th,
  .scrollable-table td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
  }
  
  .scrollable-table th {
    background-color: #f2f2f2;
  }
  .open-para {
    border-radius: 5px;
    background-color: #0d2137;
    padding: 5px;
    box-shadow: 1px 1px 4px gray;
    color: white;
    font-size: 15px;
  }
  .option-flex {
    display: flex;
    justify-content: center;
    margin: 10px 0px;
    width: 100%;
  }
  li{
    list-style: none;
  }
  ul.options {
    display: flex;
    width: 47%;
    border-radius: 5px;
    padding: 7px;
    background: white;
    justify-content: center;
    align-items: center;
  }
  .options li{
    margin: 0px 5px;
    font-size: 12px;
    font-weight: 400;
  }
  .search{
    display: flex;
    width: 48%;
    margin-left: 2%;
  }
  
  input.search-input {
    padding: 5px;
    border: none;
    border-radius: 5px;
    color: gray;
    width: 100%;
  }
  .search li{
    margin: 0px 5px;
  
  }
  .open-bet {
    padding: 9px;
  }
  
  p.odds {
    background-color: white;
    margin: 7px 1px;
    margin-bottom: 4px !important;
    padding: 4px;
    color: rgb(0, 0, 0);
    font-size: 12px;
    font-weight: 400;
  }
  p.odds strong{
    margin-left: 10px;
  }
  ul.match-rate {
    display: flex;
    justify-content: space-around;
  }
  li select{
    background-color: white;
    color: gray;
  }
  select:focus-visible{
    border: none;
  }
  li button.pre{
    border-radius: 5px;
    background: rgb(173,33,33) !important;
    background: linear-gradient(40deg, rgba(173,33,33,1) 29%, rgba(255,0,0,1) 73%) !important;
    color: white;
    box-shadow: 1px 1px 5px rgb(29, 40, 44);
    margin-right: 8px;
    border: none;
    font-size: 12px;
  }
  li button.nxt{
    padding: 5px 15px;
    border-radius: 5px;
    background: rgb(33,173,98);
    background: linear-gradient(40deg, rgba(33,173,98,1) 29%, rgba(28,250,10,1) 80%);
    color: white;
    box-shadow: 1px 1px 5px rgb(29, 40, 44);
    border: none;
    margin-right: 8px;
    font-size: 12px;
  }
  li button.num{
    padding: 5px 15px;
    border-radius: 5px;
    background-color: #0d2137;
    color: white;
    box-shadow: 1px 1px 5px rgb(29, 40, 44);
    border: none;
    margin-right: 8px;
  }
  li.input{
    border: none;
    width: 100%;
  }
  li.input input:focus{
    outline: none;
  }
  
  .open-para-entries{
    border-radius: 5px;
    background-color: #0d2137;
    padding: 5px;
    box-shadow: 1px 1px 4px gray;
    margin-top: 12px;
    color: white;
    font-size: 12px;
  }
  
  .page-link {
    position: relative;
    display: block;
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
    color: #4d5157;
    text-decoration: none;
    background-color: var(--bs-pagination-bg);
    border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-size: 10px;
  }
  
  
  .page-link:focus {
    z-index: 3;
  }
  ul {
    margin-top: 0;
    margin-bottom: 0rem !important;
  }
  
  nav.pagination-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
  }
  
  
  @media (max-width: 300px){
    input.search-input {
        display: none;
    }
  }
  