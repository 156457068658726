img.banner-images {
    width: 100%;
    margin: 2px 0px;
    height: 100px;
  }
  @media (max-width: 400px) and (max-height: 950px) {
    .image {
        width: 100% !important ;
        margin: 3px 0px;
    }
  }