.gaming-png img {
    width: 250px;
    height: 80px;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .main-footer {
    display: flex;
    background-color: black;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .main-footer-paragraph p {
    color: white;
    padding: 20px;
      text-align: justify;
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
  }
  .main-footer-content {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  
  .main-footer-payment-section{
    display: grid;
  }
  .main-footer-payment-section {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 30px 0;
    padding: 0 10px;
  }
  .main-footer-payment-section img{
    padding: 15px;
  }
  
  /* MainScreen CSS */
  .mainscreen-container{
    padding: 0 8px;
  }