.scrollable-box-image {
    overflow-x: auto;
    white-space: nowrap;
  }
    
    .scrollable-box-image::-webkit-scrollbar {
      display: none;
    }
    
    .inner-box-image {
      display: flex;
      flex-wrap: nowrap;
    }
    
    .inner-box-image > div {
      width: 100px;
      height: 50px;
      margin: 10px;
      padding: 10px;
    }
  
    .inner-box-image .box-images{
      background: none;
      border: none;
    }
  
    img.box-image {
      width: 136px;
      margin: 2px 5px 0 0px;
  }
  
    @media (max-width: 415px) and (min-width: 380px) {
      button.box-images {
        padding: 0;
    }
    img.box-image {
      margin-top: 1px;
      height: 160px;
      width: 132px;
    }
  }
    @media (max-width: 381px) and (min-width: 300px) {
      button.box-images {
        padding: 0;
    }
    img.box-image {
      margin-top: 1px;
      height: 160px;
      width: 119px;
  }
  }
    @media (max-width: 391px) and (min-width: 382px) {
      button.box-images {
        padding: 0;
    }
    img.box-image {
      margin-top: 1px;
      height: 160px;
      width: 125px;
  }
  }
    @media (max-width: 345px) and (min-width: 300px) {
      button.box-images {
        padding: 0;
    }
    img.box-image {
      margin-top: 1px;
      height: 150px;
      width: 108px;
  }
  }
  