.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .popup-content {
    background: rgb(139,144,43);
    background: linear-gradient(0deg, rgba(139,144,43,1) 16%, rgba(231,228,145,1) 81%);
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    max-width: 100%;
    z-index: 1000;
  }
  
  .close-icon {
    position: absolute;
    top: 0px;
    right: 6px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  button[type="submit"] {
    /* padding: 4px 20px; */
    /* background-color: #28a745; */
    color: #fff;
    border: none;
    /* border-radius: 4px; */
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #218838;
  }
  
  .selection-menu select{
    padding: 10px;
    border-radius: 5px;
  }
  .selection-menu select:focus-visible{
    border: none;
  }
  
  form input {
    width: 100%;
    padding: 8px;
    /* margin-bottom: 15px; */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 15px;
  }
  