.setting{
    padding: 9px;
  }
  .settings-container{
        border-radius: 5px;
        background-color: #0d2137;
        padding: 9px;
        box-shadow: 1px 1px 4px gray;
        color: white;
        font-size: 15px;
        font-weight: 300;
  }
  .settings-container strong{
    margin-left: 15px;
  }
  .stakes span{
    font-size: 25px;
    margin-left: 15px;
    margin-top: 20px;
    font-weight: 100;
  }
  .edit-stakes {
    width: 100%;
    display: grid;
    align-content: center;
    justify-content: center;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .edit-stakes input {
    margin: 10px;
    width: 85%;
    padding: 5px 0px;
    text-align: center;
  }
  .edit-stakes input:focus{
    outline: none;
  }
  .edit-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button.edit-btn{
    padding: 5px;
    font-size: 16px;
    width: 110px;
    background-color: rgb(30, 38, 45);
    color: white;
  }